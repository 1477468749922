<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Science</title>
    <path
      d="M19.7992 18.4L13.9992 10.67V6.5L15.3492 4.81C15.6092 4.48 15.3792 4 14.9592 4H9.0392C8.6192 4 8.3892 4.48 8.6492 4.81L9.9992 6.5V10.67L4.1992 18.4C3.7092 19.06 4.1792 20 4.9992 20H18.9992C19.8192 20 20.2892 19.06 19.7992 18.4Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-science',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
